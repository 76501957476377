/**
 * @generated SignedSource<<a63c2e6ddbc434f1d027cb50e73a5fa6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Icon = "ACCOUNT" | "AGE" | "ARROW_LEFT_FAT" | "AWARD" | "BACCARAT" | "BASKETBALL" | "BELT" | "BETTING" | "BINGO" | "BINGO_BALL" | "BLACKJACK" | "BOMB" | "BONUS" | "CARE" | "CASINO_LIVE" | "CHAT_BUBBLES" | "CHECK" | "CHEVRON_DOUBLE_RIGHT" | "CHEVRON_RIGHT" | "CHEVRON_RIGHT_FAT" | "CHEVRON_RIGHT_THIN_ROUNDED" | "CHRISTMAS" | "CLOCK" | "CLOSE_FAT" | "CLOSE_FAT_ROUNDED" | "CLOSE_THIN_ROUNDED" | "COIN" | "COINS" | "COLLECTION" | "DICE" | "DOWNLOAD" | "EMAIL" | "EXCLAMATION" | "EXTERNAL_LINK" | "FANTASY_SPORT" | "FULLSCREEN" | "FULLSCREEN_CLOSE" | "GIFT" | "GLOBE" | "GRAPH" | "HAMBURGER" | "HISTORY" | "HOME" | "HOT" | "HOT_BALLOON" | "HOURGLASS" | "INFO" | "INSURANCE" | "JACKPOT" | "JACKPOT_POOLS" | "KEBAB" | "LIGHTBULB" | "LOCK" | "LOGOUT" | "MAINTENANCE" | "MESSAGE" | "MINUS" | "NOTES" | "NOTIFICATION" | "OPEN_BANKING" | "OTHER_GAMES" | "PACKAGE" | "PAUSE" | "PHONE" | "PLAY" | "PLAYERS" | "PLUS" | "POKER" | "POKER_HAND" | "ROOF" | "ROULETTE" | "ROUNDED_SQUARE" | "RULES" | "SEARCH" | "SETTINGS" | "SHIELD" | "SLIDERS" | "SLOTS" | "SLOT_MACHINE" | "SOUND_OFF" | "SOUND_ON" | "STAR_BADGE" | "STOP_WATCH" | "SUPPORT" | "SUPPORT_CHAT" | "SUPPORT_HANDS" | "TICKET_GRID" | "TICKET_SPECIAL" | "TOGGLE_SEE" | "TOGGLE_UNSEE" | "TOOLS" | "TRASH" | "TROPHY" | "TURNOVER_CONTRIBUTION" | "UPLOAD" | "VIDEO_POKER" | "WALLET";
export type IconAlignment = "LEFT" | "RIGHT";
export type LinkAppearanceType = "BUTTON" | "TEXT";
import { FragmentRefs } from "relay-runtime";
export type FlowRouterButton_button$data = {
  readonly appearanceType: LinkAppearanceType;
  readonly ariaLabel: string | null;
  readonly iconAlignment: IconAlignment;
  readonly iconName: Icon | null;
  readonly text: string;
  readonly " $fragmentSpreads": FragmentRefs<"useGetFlowUrl_button">;
  readonly " $fragmentType": "FlowRouterButton_button";
};
export type FlowRouterButton_button$key = {
  readonly " $data"?: FlowRouterButton_button$data;
  readonly " $fragmentSpreads": FragmentRefs<"FlowRouterButton_button">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FlowRouterButton_button",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appearanceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ariaLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iconName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iconAlignment",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGetFlowUrl_button"
    }
  ],
  "type": "Button",
  "abstractKey": null
};

(node as any).hash = "5681bf46f01588347c9ed60eda46ee1f";

export default node;
